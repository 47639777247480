import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet"

function MarTech(props) {
  const {
    facebook,
    google
  } = props

  return (
    <Helmet>
      {google ? (
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-62140698-4"></script>
      ) : null}

      {google ? (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-62140698-4');
          `}
        </script>
      ) : null}

      {facebook ? (
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '2311160685560738');
            fbq('track', 'PageView');
          `}
        </script>
      ) : null}

      {facebook ? (
        <noscript>
          {`
            <img 
              height="1" 
              width="1" 
              style="display:none"
              src="https://www.facebook.com/tr?id=2311160685560738&ev=PageView&noscript=1"
            />
          `}
        </noscript>
      ) : null}

    </Helmet>
  );
}

MarTech.defaultProps = {
  facebook: true,
  google: true
}
MarTech.propTypes = {
  facebook: PropTypes.bool,
  google: PropTypes.bool,
};

export default MarTech;
