import React from "react";
import { Location } from "@reach/router";
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MarTech from "../../components/MarTech";
import ImgWithOverlay from "../../components/img-with-overlay";

function Startups(props) {
  const {
    startupCafe
  } = props.data

  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title="For App Startups"
            pathname={location.pathname}
            keywords={[`gortek`, `app`, `startup`, `business`]}
          />
        )}
      </Location>

      <MarTech />

      <ImgWithOverlay
        filename="startup-cover.jpg"
        height="300px"
        backgroundColor="#1464f6bb"
        backgroundPosition="center"
      >
        <div className="flex flex-col h-full">
          <h1 className="text-white mt-auto w-full text-center">
            For App Startups
          </h1>
          <h2 className="text-white text-3xl w-full text-center pb-4">
            Gortek is the software development company for startup founders
          </h2>
        </div>
      </ImgWithOverlay>

      <section className="mt-20">
        <div className="flex md:flex-row-reverse flex-col">
          <div className="md:w-1/2 w-full p-3">
            <Img
              alt="Startup coffee shop meeting"
              title="Startup coffee shop meeting"
              fluid={startupCafe.childImageSharp.fluid}
            />
          </div>
          <div className="md:w-1/2 w-full">
            <h2 className="text-primary text-3xl">You've got an app idea</h2>
            <p className="mt-4">
              Gortek is different than most software development firms.
            </p>
            <p className="mt-4">
              You may be surprised to learn that we actually <em>don't</em> want
              to develop your software. At least not until we've achieved what's
              known as "Product - Market Fit" with the idea first.
            </p>

            <h2 className="text-primary text-3xl mt-8">Testing and Validation</h2>

            <p className="mt-4">
              If you're not familiar with the{" "}
              <a
                className="text-primary"
                href="https://amzn.to/2MJ2G5O"
                target="_blank"
                rel="noopener noreferrer"
              >
                Lean Startup
              </a>{" "}
              approach to launch a business, the premise is that everything is
              an unknown.
            </p>
            <p className="mt-4">
              Your product, your target market, and your unique angle. &mdash;
              All unknown quantities that must be tested and proven to work in
              your business context.
            </p>
            <p className="mt-4">
              Because of this, you need to validate each of them. If any part of
              your business approach doesn't work you want to find out as soon
              as possible and change course.
            </p>

            <h2 className="text-primary text-3xl mt-8">Customer Development</h2>

            <p className="mt-4">
              If you're developing an MVP for your startup, or a new product for
              your business, Gortek will work with you to crystalize your app
              idea and test it with your target market. We want to do this{" "}
              <em>before</em> charging down the path software development.
            </p>
            <p>
              This process of testing the business concept to ensure people want
              to buy what you are building before you build it is often called
              "Customer Development".
            </p>

            <h2 className="text-primary text-3xl mt-8">A Little Help?</h2>

            <p className="mt-4">
              If you're ready to talk through your idea, drop us a line to set
              up a call.
              {/*  &mdash;{" "} <span className="text-primary">hello@gortek.com</span>. */}
            </p>
          </div>
        </div>
        <div className="ml-4 mt-6">
          <p className="">
            <strong>
              Say <span className="text-primary">hello@gortek.com</span>.
            </strong>
          </p>
          <p className="mt-2 ml-4">
            Copy & paste this into your email, along with your brief answers.
          </p>
          <ul
            className="ml-4 sm:ml-6 md:ml-8 lg:ml-10"
            style={{
              listStyleType: "disc",
              listStylePosition: "inside"
            }}
          >
            <li>
              Tell us about you (including your business ownership experience)
            </li>
            <li>Tell us about the project...</li>
            <li>How far have you progressed with this project?</li>
            <li>Have you already tried developing this app before?</li>
            <li>What is your time frame?</li>
            <li>What is your budget?</li>
          </ul>
        </div>
      </section>

      {/* <div className="mt-10"> */}
      {/*   <Link to="/blog/recommended-reading-for-startups"> */}
      {/*     <h4 className="text-primary underline"> */}
      {/*       Check out our recommended reading list for startups */}
      {/*     </h4> */}
      {/*   </Link> */}
      {/* </div> */}

      <div className="h-16" />
    </Layout>
  );
}

export default Startups;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    startupCafe: file(relativePath: { eq: "startup.jpg" }) {
      ...fluidImage
    }
  }
`
